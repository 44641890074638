* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.ant-btn-primary:hover {
  background-color: #0070ba !important;
}
.ant-btn-default:hover {
  color: #0070ba !important;
  border-color: #0070ba !important;
}

.ant-progress-bg {
  height: 6px !important;
  margin: 4px;
}
.ant-progress-inner {
  width: 100%;
}

.ant-menu-item-selected,
.ant-menu-item-active {
  color: rgba(0, 0, 0, 0.65) !important;
  border-bottom: none !important;
}
.ant-menu-item {
  border-bottom: none !important;
}
.ant-select {
  .ant-select-selection {
    border-radius: 15px !important;
  }
}
.ant-select-open {
  .ant-select-selection {
    border-radius: 15px 15px 3px 3px !important;
  }
}

.ant-checkbox-inner {
  background-color: #fafbfe !important;
  border: solid 1px #919db4 !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-checked .ant-checkbox-inner {
  border: solid 1px #919db4;
}

.ant-select-dropdown-menu {
  margin: 0 !important;
  padding: 0 !important;
}

.ant-select-dropdown-menu::before {
  margin: 0 !important;
  padding: 0 !important;
}

.ant-select-dropdown--single {
  margin: 0 !important;
  padding: 0 !important;
}
.ant-select-dropdown-placement-bottomLeft {
  margin: 0 !important;
  padding: 0 !important;
}
.ant-select-dropdown-hidden {
  margin: 0 !important;
  padding: 0 !important;
}

.scroll-1 {
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: #eceff5;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #a2adc4 !important;
    border-radius: 20px;
  }
}

.ant-switch {
  border: solid 1px #a2adc4 !important;
  background-color: #cdd3e0 !important;
  &:after {
    background-color: #919db4 !important;
  }
}

.ant-switch-checked {
  border: solid 1px #a2adc4 !important;
  background-color: #d9e7ff !important;
  &:after {
    background-color: #474588 !important;
  }
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 !important;
}

.single-edit {
  .react-datepicker {
    top: 500px !important;
    left: 56% !important;
    position: absolute;
  }
}

.highLight {
  background-color: red !important;
}

.strategy-modal {
  .ant-form-item {
    margin: 0 !important;
  }
  .ant-form-item-label {
    line-height: inherit !important;
  }
  label {
    margin-bottom: 0 !important;
  }
}

.normal-font {
  label {
    font-weight: normal !important;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
  }
}

.inline-form-item {
  display: flex !important;
  flex-wrap: wrap;
  align-items: center;
  label {
    // margin-right: 10px;
    display: inline-block;
  }
  input {
    width: 50px !important;
    display: inline-block;
  }
}

.single-edit {
  .ant-btn-circle,
  .ant-btn-circle-outline {
    height: 18px;
    width: 18px;
  }
}

$backgroundLightBlue: #edf4fc;
$backgroundDarkBlue: #e4edf7;
$backgroundLightGray: #f4f6fa;
$backgroundDarkGray: #fafbfe;
$_blue: #0070ba;
$_darkBlue: #233b69;
$_lightBlue: #d9e7ff;
$_eggplantBlue: #3f435c;
$_dirtyBlue: #586784;
$_dirtyLightBlue: #a2adc4;
$purple: #474588;
$beige: #ebebeb;
$green: #08ac08;
$pink: #ff3f71;

$font-primary: 'Heebo', sans-serif;

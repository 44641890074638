@import './utils/variables.scss';
@import './utils/visually-hidden.scss';
@import './base.scss';
@import './spinner.scss';
@import './utils/typography.scss';
@import './utils/font.scss';

html,
body {
  font-family: $font-primary;
}

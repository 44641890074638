@font-face {
  font-family: 'Heebo';
  src: url('../assets/fonts/heebo.woff') format('woff'),
    url('../assets/fonts/heebo.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Heebo-Thin';
  src: url('../assets/fonts/Heebo-Thin.woff') format('woff');
  font-weight: 100;
}
@font-face {
  font-family: 'Heebo-Medium';
  src: url('../assets/fonts/Heebo-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Heebo-Bold';
  src: url('../assets/fonts/Heebo-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Heebo-Light';
  src: url('../assets/fonts/Heebo-Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
